'use client';

import { Languages, TrackerNext } from '@grupa-pracuj/tracker-relax';

import useEnvironment from 'src/context/EnvironmentContext';

function InitTracker({ cspNonce }: { cspNonce: string }) {
  const { appName, appVersion, cookieDomain, trackerCollectorEndpoint, trackerCoreUrl, trackerEnabled } =
    useEnvironment();

  return (
    <TrackerNext
      appLanguage={Languages.PL}
      appName={appName}
      appVersion={appVersion}
      collectorEndpoint={trackerCollectorEndpoint}
      cookieDomain={cookieDomain}
      coreUrl={trackerCoreUrl}
      cspNonce={cspNonce}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      enabled={trackerEnabled}
    />
  );
}

export default InitTracker;
