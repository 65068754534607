"use client";
import re, { useCallback as g, useEffect as ie, useMemo as ce } from "react";
import { jsx as le } from "react/jsx-runtime";
function v({ payload: t, dataLayerIndex: e = null }) {
  if (window.dataLayer) {
    const { dataLayer: n } = window;
    e === null ? n.push(t) : n[e] = t;
  }
}
function U(t) {
  return t || "";
}
const ue = ({
  isLoggedIn: t,
  userId: e,
  loggedInLocation: n,
  loggedInLevel: l
}) => {
  const i = t && e, a = {
    "Czy zalogowany": t ? "Zalogowany" : "Niezalogowany",
    "Typ ekosystem": "aplikowanie",
    "Typ odbiorca": "b2c"
  };
  return e !== null && (a["Id zalogowany"] = U(i)), n !== null && (a["Lokalizacja zalogowany"] = U(n)), l !== null && (a["Poziom zalogowany"] = U(l)), a;
}, se = ({
  isLoggedIn: t,
  userId: e,
  loggedInLocation: n,
  loggedInLevel: l,
  path: i,
  rawUrl: a
}) => {
  const u = {
    "Czy zalogowany": t ? "Zalogowany" : "Niezalogowany",
    "Id zalogowany": t && e || "",
    "Lokalizacja zalogowany": n || "",
    "Poziom zalogowany": l || "",
    "Typ ekosystem": "aplikowanie",
    "Typ odbiorca": "b2c",
    event: "pageView",
    GTMurl: i
  };
  return a !== null && (u.GTMRawUrl = a), u;
}, de = ({ path: t }) => ({
  event: "GAevent",
  eventCategory: "formularz.pracuj.pl",
  eventAction: "cv_i_inne_dokumenty",
  eventLabel: "dodaj_plik",
  GTMurl: `/aplikowanie${t}`
}), fe = ({
  customerId: t,
  offerId: e
}) => ({
  event: "CriteoTransaction",
  customerId: t,
  OfferId: e
}), pe = ({
  offerIds: t
}) => ({ criteo_rec_offer: t }), ye = ({
  transactionId: t,
  sourceName: e,
  regionName: n,
  countryName: l,
  offerId: i,
  jobTitle: a,
  categories: y
}) => ({
  transactionId: t,
  transactionAffiliation: e,
  transactionTotal: 1,
  transactionTax: 0,
  transactionShipping: 0,
  transactionRegion: n,
  transactionCountry: l,
  transactionProducts: [
    {
      sku: i,
      name: `${e}_${a}`,
      category: `${e}${y.join(" ")}`,
      price: 1,
      quantity: 1
    }
  ],
  event: "trackTrans"
}), me = ({
  transactionId: t,
  sourceName: e,
  offerId: n
}) => ({
  transactionId: t,
  transactionAffiliation: e,
  Szukajacy_pracy: "Aplikowal",
  transactionTotal: 1,
  transactionTax: 0,
  transactionShipping: 0,
  transactionProducts: [
    {
      sku: n,
      price: 1,
      quantity: 1
    }
  ],
  event: "trackTrans"
}), _e = ({
  ecosystemType: t,
  recipientType: e
}) => ({
  "Typ ekosystem": t,
  "Typ odbiorca": e
}), ge = () => ({ Szukajacy_pracy: "Aplikowal" }), ve = () => ({ event: "socSG" }), he = ({ offerId: t }) => ({
  event: "GAevent",
  eventCategory: "Phone_number",
  eventAction: "view",
  eventLabel: t
}), ke = ({ offerId: t, place: e }) => ({
  event: "GAevent",
  eventCategory: "Ogloszenie",
  eventAction: `Btn_aplikuj_${e}`,
  eventLabel: `Oferta_${t}_boxTrue`
}), G = {
  ClickApplyOnline: "klik aplikuj online",
  ApplyOnline: "aplikacja online",
  ClickApplyATS: "klik aplikuj ATS",
  ApplyATSContinue: "kontynuuj_ats",
  ClickApplyErecruiter: "klik aplikuj erecruiter",
  ApplyErecruiter: "aplikacja erecruiter",
  ClickMultiApply: "klik aplikuj multiaplikowanie",
  MultiApply: "aplikacja multiaplikowanie"
}, F = {
  AddToCart: "add_to_cart",
  Purchase: "purchase"
}, be = (t) => [
  G.ClickApplyOnline,
  G.ClickApplyATS,
  G.ClickApplyErecruiter,
  G.ClickMultiApply
].includes(t) ? F.AddToCart : F.Purchase, we = ({
  applicationStage: t,
  categoriesName: e,
  jobTitle: n,
  offerId: l,
  applyTypeId: i,
  transactionId: a = String(Date.now())
}) => {
  const y = {
    email: 1,
    online: 2,
    list: 3
  }, { appTypeName: u } = [
    { appType: y.email, appTypeName: "email" },
    { appType: y.online, appTypeName: "online" },
    { appType: y.list, appTypeName: "list" }
  ].find(({ appType: c }) => c === i) || { appTypeName: "ApplyTo" };
  return {
    event: be(t),
    etap_aplikacji: t,
    transactionId: a,
    transactionAffiliation: "Przycisk_aplikowania",
    szukajacy_pracy: "Aplikowal",
    ecommerce: {
      currency: "PLN",
      value: 1,
      items: [
        {
          item_id: l,
          item_name: `${n}_${u}`,
          item_category: e,
          transaction_id: a,
          price: 1,
          quantity: 1
        }
      ]
    }
  };
}, Ce = ({
  categoriesName: t,
  workplace: e,
  employerName: n,
  jobTitle: l,
  offerId: i,
  typesOfContracts: a,
  salaries: y,
  positionLevelsName: u
}) => ({
  event: "ee_apply",
  ecommerce: {
    add: {
      products: [
        {
          categoryNames: t || "",
          name: l,
          id: i,
          price: "1",
          brand: n,
          variant: e || "",
          quantity: 1,
          dimension6: u,
          dimension8: a,
          dimension9: y.map(({ from: c, to: p }) => `${c} - ${p}`).join(", ")
        }
      ]
    }
  }
});
function ze({ userId: t }) {
  const e = g(
    (o, r = null) => {
      const j = ue({ userId: t, ...o });
      v({ payload: j, dataLayerIndex: r });
    },
    [t]
  ), n = g(
    (o) => {
      const r = se({ userId: t, ...o });
      v({ payload: r });
    },
    [t]
  ), l = g((o) => {
    const r = de(o);
    v({ payload: r });
  }, []), i = g((o) => {
    const r = fe(o);
    v({ payload: r });
  }, []), a = g((o) => {
    const r = pe(o);
    v({ payload: r });
  }, []), y = g((o) => {
    const r = ye(o);
    v({ payload: r });
  }, []), u = g((o) => {
    const r = me(o);
    v({ payload: r });
  }, []), c = g((o) => {
    const r = _e(o);
    v({ payload: r });
  }, []), p = g(() => {
    const o = ge();
    v({ payload: o });
  }, []), s = g(() => {
    const o = ve();
    v({ payload: o });
  }, []), d = g((o) => {
    const r = he(o);
    v({ payload: r });
  }, []), f = g((o) => {
    const r = ke(o);
    v({ payload: r });
  }, []), h = g((o) => {
    const r = we(o);
    v({ payload: r });
  }, []), E = g((o) => {
    const r = Ce(o);
    v({ payload: r });
  }, []);
  return {
    sendGAInitial: e,
    sendGAPageView: n,
    sendGaFileUpload: l,
    sendGaCriteoTransaction: i,
    sendGaCriteoRecommendationsOffers: a,
    sendGaTrackTrans: y,
    sendGaTrackTransMultiapply: u,
    sendGaEcosystem: c,
    sendGaCandidate: p,
    sendGaSocSG: s,
    sendGaPhoneNumber: d,
    sendGaApplication: f,
    sendGaApplicationCategory: h,
    sendGaApplicationInformation: E
  };
}
function oe({ container: t }) {
  return `
    (function (w, d, s, l, i) {
      var n = d.querySelector("[nonce]");
      w[l] = w[l] || [];
      w[l].push({ nonce: n.nonce || n.getAttribute("nonce") });
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      n && j.setAttribute("nonce", n.nonce || n.getAttribute("nonce"));
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "${t}");
  `;
}
function He({ container: t, cspNonce: e }) {
  return ie(() => {
    const n = document.createElement("script");
    return n.nonce = e, n.innerHTML = oe({ container: t }), n.id = "gtm-script", document.body.append(n), () => {
      n.remove();
    };
  }, []), null;
}
function Ae(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
var z = { exports: {} }, H = {}, Z;
function Me() {
  if (Z) return H;
  Z = 1, Object.defineProperty(H, "__esModule", {
    value: !0
  }), H.default = t;
  function t() {
    return e.apply(this, arguments);
  }
  function e() {
    return e = Object.assign || function(n) {
      for (var l = 1; l < arguments.length; l++) {
        var i = arguments[l];
        for (var a in i)
          Object.prototype.hasOwnProperty.call(i, a) && (n[a] = i[a]);
      }
      return n;
    }, e.apply(this, arguments);
  }
  return H;
}
var $ = {}, J;
function Te() {
  if (J) return $;
  J = 1, Object.defineProperty($, "__esModule", {
    value: !0
  }), $.default = t;
  function t(n, l) {
    if (!l && n && n.__esModule)
      return n;
    if (n === null || typeof n != "object" && typeof n != "function")
      return {
        default: n
      };
    var i = e(l);
    if (i && i.has(n))
      return i.get(n);
    var a = {}, y = Object.defineProperty && Object.getOwnPropertyDescriptor;
    for (var u in n)
      if (u !== "default" && Object.prototype.hasOwnProperty.call(n, u)) {
        var c = y ? Object.getOwnPropertyDescriptor(n, u) : null;
        c && (c.get || c.set) ? Object.defineProperty(a, u, c) : a[u] = n[u];
      }
    return a.default = n, i && i.set(n, a), a;
  }
  function e(n) {
    if (typeof WeakMap != "function") return null;
    var l = /* @__PURE__ */ new WeakMap(), i = /* @__PURE__ */ new WeakMap();
    return (e = function(a) {
      return a ? i : l;
    })(n);
  }
  return $;
}
var D = {}, Q;
function qe() {
  if (Q) return D;
  Q = 1, Object.defineProperty(D, "__esModule", {
    value: !0
  }), D.default = t;
  function t(e, n) {
    if (e == null) return {};
    var l = {}, i = Object.keys(e), a, y;
    for (y = 0; y < i.length; y++)
      a = i[y], !(n.indexOf(a) >= 0) && (l[a] = e[a]);
    return l;
  }
  return D;
}
var L = {}, x = {}, X;
function Ee() {
  if (X) return x;
  X = 1, Object.defineProperty(x, "__esModule", {
    value: !0
  }), x.default = t;
  function t(e) {
    return e && e.__esModule ? e : {
      default: e
    };
  }
  return x;
}
var Y;
function je() {
  if (Y) return L;
  Y = 1, Object.defineProperty(L, "__esModule", {
    value: !0
  }), L.HeadManagerContext = void 0;
  var t = Ee().default, e = t(re);
  const n = e.default.createContext({});
  return L.HeadManagerContext = n, process.env.NODE_ENV !== "production" && (n.displayName = "HeadManagerContext"), L;
}
var B = { exports: {} }, ee;
function Oe() {
  return ee || (ee = 1, function(t, e) {
    Object.defineProperty(e, "__esModule", {
      value: !0
    }), e.default = n, e.isEqualNode = a, e.DOMAttributeNames = void 0;
    function n() {
      return {
        mountedInstances: /* @__PURE__ */ new Set(),
        updateHead: (u) => {
          const c = {};
          u.forEach((d) => {
            if (
              // If the font tag is loaded only on client navigation
              // it won't be inlined. In this case revert to the original behavior
              d.type === "link" && d.props["data-optimized-fonts"]
            ) {
              if (document.querySelector(`style[data-href="${d.props["data-href"]}"]`))
                return;
              d.props.href = d.props["data-href"], d.props["data-href"] = void 0;
            }
            const f = c[d.type] || [];
            f.push(d), c[d.type] = f;
          });
          const p = c.title ? c.title[0] : null;
          let s = "";
          if (p) {
            const { children: d } = p.props;
            s = typeof d == "string" ? d : Array.isArray(d) ? d.join("") : "";
          }
          s !== document.title && (document.title = s), [
            "meta",
            "base",
            "link",
            "style",
            "script"
          ].forEach((d) => {
            y(d, c[d] || []);
          });
        }
      };
    }
    const l = {
      acceptCharset: "accept-charset",
      className: "class",
      htmlFor: "for",
      httpEquiv: "http-equiv",
      noModule: "noModule"
    };
    e.DOMAttributeNames = l;
    function i({ type: u, props: c }) {
      const p = document.createElement(u);
      for (const f in c) {
        if (!c.hasOwnProperty(f) || f === "children" || f === "dangerouslySetInnerHTML" || c[f] === void 0) continue;
        const h = l[f] || f.toLowerCase();
        u === "script" && (h === "async" || h === "defer" || h === "noModule") ? p[h] = !!c[f] : p.setAttribute(h, c[f]);
      }
      const { children: s, dangerouslySetInnerHTML: d } = c;
      return d ? p.innerHTML = d.__html || "" : s && (p.textContent = typeof s == "string" ? s : Array.isArray(s) ? s.join("") : ""), p;
    }
    function a(u, c) {
      if (u instanceof HTMLElement && c instanceof HTMLElement) {
        const p = c.getAttribute("nonce");
        if (p && !u.getAttribute("nonce")) {
          const s = c.cloneNode(!0);
          return s.setAttribute("nonce", ""), s.nonce = p, p === u.nonce && u.isEqualNode(s);
        }
      }
      return u.isEqualNode(c);
    }
    function y(u, c) {
      const p = document.getElementsByTagName("head")[0], s = p.querySelector("meta[name=next-head-count]");
      if (process.env.NODE_ENV !== "production" && !s) {
        console.error("Warning: next-head-count is missing. https://nextjs.org/docs/messages/next-head-count-missing");
        return;
      }
      const d = Number(s.content), f = [];
      for (let o = 0, r = s.previousElementSibling; o < d; o++, r = (r == null ? void 0 : r.previousElementSibling) || null) {
        var h;
        (r == null || (h = r.tagName) == null ? void 0 : h.toLowerCase()) === u && f.push(r);
      }
      const E = c.map(i).filter((o) => {
        for (let r = 0, j = f.length; r < j; r++) {
          const V = f[r];
          if (a(V, o))
            return f.splice(r, 1), !1;
        }
        return !0;
      });
      f.forEach((o) => {
        var r;
        return (r = o.parentNode) == null ? void 0 : r.removeChild(o);
      }), E.forEach((o) => p.insertBefore(o, s)), s.content = (d - f.length + E.length).toString();
    }
    (typeof e.default == "function" || typeof e.default == "object" && e.default !== null) && typeof e.default.__esModule > "u" && (Object.defineProperty(e.default, "__esModule", { value: !0 }), Object.assign(e.default, e), t.exports = e.default);
  }(B, B.exports)), B.exports;
}
var W = { exports: {} }, te;
function Se() {
  return te || (te = 1, function(t, e) {
    Object.defineProperty(e, "__esModule", {
      value: !0
    }), e.cancelIdleCallback = e.requestIdleCallback = void 0;
    const n = typeof self < "u" && self.requestIdleCallback && self.requestIdleCallback.bind(window) || function(i) {
      let a = Date.now();
      return setTimeout(function() {
        i({
          didTimeout: !1,
          timeRemaining: function() {
            return Math.max(0, 50 - (Date.now() - a));
          }
        });
      }, 1);
    };
    e.requestIdleCallback = n;
    const l = typeof self < "u" && self.cancelIdleCallback && self.cancelIdleCallback.bind(window) || function(i) {
      return clearTimeout(i);
    };
    e.cancelIdleCallback = l, (typeof e.default == "function" || typeof e.default == "object" && e.default !== null) && typeof e.default.__esModule > "u" && (Object.defineProperty(e.default, "__esModule", { value: !0 }), Object.assign(e.default, e), t.exports = e.default);
  }(W, W.exports)), W.exports;
}
var ne;
function Pe() {
  return ne || (ne = 1, function(t, e) {
    "client";
    Object.defineProperty(e, "__esModule", {
      value: !0
    }), e.handleClientScriptLoad = h, e.initScriptLoader = r, e.default = void 0;
    var n = Me().default, l = Te().default, i = qe().default, a = l(re), y = je(), u = Oe(), c = Se();
    const p = /* @__PURE__ */ new Map(), s = /* @__PURE__ */ new Set(), d = [
      "onLoad",
      "onReady",
      "dangerouslySetInnerHTML",
      "children",
      "onError",
      "strategy"
    ], f = (m) => {
      const { src: _, id: C, onLoad: S = () => {
      }, onReady: T = null, dangerouslySetInnerHTML: b, children: q = "", strategy: I = "afterInteractive", onError: O } = m, A = C || _;
      if (A && s.has(A))
        return;
      if (p.has(_)) {
        s.add(A), p.get(_).then(S, O);
        return;
      }
      const M = () => {
        T && T(), s.add(A);
      }, k = document.createElement("script"), N = new Promise((w, R) => {
        k.addEventListener("load", function(P) {
          w(), S && S.call(this, P), M();
        }), k.addEventListener("error", function(P) {
          R(P);
        });
      }).catch(function(w) {
        O && O(w);
      });
      b ? (k.innerHTML = b.__html || "", M()) : q ? (k.textContent = typeof q == "string" ? q : Array.isArray(q) ? q.join("") : "", M()) : _ && (k.src = _, p.set(_, N));
      for (const [w, R] of Object.entries(m)) {
        if (R === void 0 || d.includes(w))
          continue;
        const P = u.DOMAttributeNames[w] || w.toLowerCase();
        k.setAttribute(P, R);
      }
      I === "worker" && k.setAttribute("type", "text/partytown"), k.setAttribute("data-nscript", I), document.body.appendChild(k);
    };
    function h(m) {
      const { strategy: _ = "afterInteractive" } = m;
      _ === "lazyOnload" ? window.addEventListener("load", () => {
        c.requestIdleCallback(() => f(m));
      }) : f(m);
    }
    function E(m) {
      document.readyState === "complete" ? c.requestIdleCallback(() => f(m)) : window.addEventListener("load", () => {
        c.requestIdleCallback(() => f(m));
      });
    }
    function o() {
      [
        ...document.querySelectorAll('[data-nscript="beforeInteractive"]'),
        ...document.querySelectorAll('[data-nscript="beforePageRender"]')
      ].forEach((_) => {
        const C = _.id || _.getAttribute("src");
        s.add(C);
      });
    }
    function r(m) {
      m.forEach(h), o();
    }
    function j(m) {
      const { id: _, src: C = "", onLoad: S = () => {
      }, onReady: T = null, strategy: b = "afterInteractive", onError: q } = m, I = i(m, [
        "id",
        "src",
        "onLoad",
        "onReady",
        "strategy",
        "onError"
      ]), { updateScripts: O, scripts: A, getIsSsr: M } = a.useContext(y.HeadManagerContext), k = a.useRef(!1);
      a.useEffect(() => {
        const w = _ || C;
        k.current || (T && w && s.has(w) && T(), k.current = !0);
      }, [
        T,
        _,
        C
      ]);
      const N = a.useRef(!1);
      return a.useEffect(() => {
        N.current || (b === "afterInteractive" ? f(m) : b === "lazyOnload" && E(m), N.current = !0);
      }, [
        m,
        b
      ]), (b === "beforeInteractive" || b === "worker") && (O ? (A[b] = (A[b] || []).concat([
        n({
          id: _,
          src: C,
          onLoad: S,
          onReady: T,
          onError: q
        }, I)
      ]), O(A)) : M && M() ? s.add(_ || C) : M && !M() && f(m)), null;
    }
    Object.defineProperty(j, "__nextScript", {
      value: !0
    });
    var V = j;
    e.default = V, (typeof e.default == "function" || typeof e.default == "object" && e.default !== null) && typeof e.default.__esModule > "u" && (Object.defineProperty(e.default, "__esModule", { value: !0 }), Object.assign(e.default, e), t.exports = e.default);
  }(z, z.exports)), z.exports;
}
var K, ae;
function Le() {
  return ae || (ae = 1, K = Pe()), K;
}
var Ie = Le();
const Ne = /* @__PURE__ */ Ae(Ie);
function $e({ container: t, cspNonce: e }) {
  const n = ce(() => oe({ container: t }), []);
  return /* @__PURE__ */ le(Ne, { id: "gtm-script", nonce: e, children: n });
}
export {
  G as ApplicationStageEnum,
  F as EventTypeEnum,
  $e as GtmNext,
  He as GtmReact,
  ke as application,
  we as applicationCategory,
  Ce as applicationInformation,
  ge as candidate,
  pe as criteoRecommendationsOffers,
  fe as criteoTransaction,
  _e as ecosystem,
  de as fileUpload,
  ue as initial,
  se as pageView,
  he as phoneNumber,
  ve as socSG,
  ye as trackTrans,
  me as trackTransMultiapply,
  ze as useGtm
};
