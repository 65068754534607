'use client';

import React, { useEffect } from 'react';
import { GtmNext, useGtm } from '@grupa-pracuj/gtm-relax';

import useEnvironment from 'src/context/EnvironmentContext';

function InitGtm({ cspNonce }: { cspNonce: string }): React.JSX.Element | null {
  const { sendGaEcosystem } = useGtm({});
  const { gtmContainerId } = useEnvironment();

  if (!gtmContainerId) {
    return null;
  }

  useEffect(() => {
    const loginEcosystemPayload = {
      ecosystemType: 'login',
      recipientType: 'b2c',
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sendGaEcosystem(loginEcosystemPayload);
  }, []);

  return <GtmNext container={gtmContainerId} cspNonce={cspNonce} />;
}

export default InitGtm;
