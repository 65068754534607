'use client';

import axios from 'axios';
import { usePathname } from 'next/navigation';
import {
  Header,
  getNavigation as getHeaderNavigation,
  getConfig,
  availableHeaderLayouts,
  availableHeaderMobileLayouts,
} from '@grupa-pracuj/header-library-sip';
import { CookieAgreements, getNavigation as getCookieNavigation } from '@grupa-pracuj/cookies-library-sip/server';

import { useAnalytics } from 'src/analytics';
import useBusinessVariables from 'src/context/BusinessVariablesContext';
import useEnvironment from 'src/context/EnvironmentContext';
import getVersionHeaders from 'src/utils/get-version-headers';

const http = axios.create({
  headers: {
    ...getVersionHeaders(),
  },
  withCredentials: true,
});

function AppHeader() {
  const businessVariables = useBusinessVariables();
  const pathname = usePathname();
  const withReturnButton = pathname !== '/';
  const { alabamaGatewayAddress } = useEnvironment();
  const { v3 } = useAnalytics();

  if (Object.keys(businessVariables).length === 0) {
    return null;
  }

  http.defaults.baseURL = alabamaGatewayAddress;

  return (
    <>
      <Header
        analytics={v3.analytics}
        http={http}
        layoutDesktop={availableHeaderLayouts.simple}
        layoutMobile={availableHeaderMobileLayouts.simple}
        navigation={getHeaderNavigation(businessVariables)}
        config={{
          ...getConfig(),
          withReturnButton,
        }}
      />
      <CookieAgreements http={http} navigation={getCookieNavigation(businessVariables)} />
    </>
  );
}

export default AppHeader;
